import React from 'react'
import CaseStudyLayout, {
  Quote,
  StarDivider,
  SliderDivider,
  CustomerPhoto,
  CallpageHero,
  OtherCaseStudies,
} from '../../components/casestudy/caseStudyLayout'
import Seo from '../../components/seo'

import shareImage from '../../components/casestudy/images/callpageShare.png'

const CallpageCFO = () => {
  return (
    <CaseStudyLayout
      title="Callpage reduced time for getting KPIs from 15 days to an hour"
      hero={<CallpageHero />}
    >
      <Seo
        title="Probe for CallPage - case study"
        description={
          'Probe helps Callpage to reduce time for preprating monthly reporting from 15 days to an hour'
        }
        keywords={[
          'business analytics',
          'callpage.io',
          'saas',
          'excel',
          'spreadsheets',
          'cfo',
          'analytics',
        ]}
        twitterImg={shareImage}
        mainpageImg={shareImage}
      />
      <CustomerPhoto
        name="Aleksander Gorczyca"
        title="Chief Financial Officer"
        company="CallPage"
        webPage="https://callpage.io"
      />
      <div className="py-5">
        <h2>What do you do at CallPage?</h2>
        <p>
          I am Chief Financial Office, but in startups it means much more, as my
          responsibilities includes different areas such as finance, legal,
          operations and administration.
        </p>
      </div>
      <StarDivider />
      <div className="py-5">
        <h2>What’s the problem that Probe is solving for you?</h2>
        <p>
          First of all, calculation of our MRR and other KPIs, which was done
          manually before and took ages. We also use Probe for visualization of
          data for our investors and team members. We analyze data using Probe
          to get more information about our business.
        </p>
      </div>
      <Quote text="Before implementing Probe, the process of getting KPIs took up to 15 days. Right now, we have results on first day of next month." />
      <StarDivider />
      <div className="py-5">
        <h2>
          Which other solutions have you tried before Probe? Why did they fail?
        </h2>
        <p>
          We used ChartMogul for some time. However it was expensive, was not
          tailored to our needs and we had to do a lot of stuff manually
          anyways. Additionally, it was quite difficult to use on a daily basis.
        </p>
      </div>
      <div className="py-5">
        <h2>What was important for you when trying to solve this problem?</h2>
        <p>
          We were looking for a partner/software on which we could trust and be
          sure that KPI are calculated correctly. Furthermore, as we have lots
          of extraordinary cases, we were looking for someone who is flexible
          enough to make sure that soft will take into account all of these
          special cases.
        </p>
      </div>
      <StarDivider />
      <div className="py-5">
        <h2>What did the implementation process look like?</h2>
        <p>
          We had several meetings with Probe team, during which we have
          discussed our needs. After that we had several phases of
          implementation: tests, dual systems (using manual and Probe at the
          same time) and final implementation. Right now we check results every
          month, but there has been nothing to correct recently.
        </p>
      </div>
      <StarDivider />
      <div className="py-5">
        <h2>
          How did it change after adopting Probe? Did it become easier? Faster?
          Do you have any numbers to back this up?
        </h2>
        <p>
          Before implementing Probe process of getting KPIs and especially MRR
          took up to 15 days. It was half of the FTE. Right now, we have results
          on first day of next month.
        </p>
      </div>
      <div className="py-5">
        <h2>Anything else you wanna add?</h2>
        <p>
          It is always a pleasure to work with Probe team. They are always
          willing to help with additional data or provide information. I would
          recommend Probe to anyone.
        </p>
      </div>
      <Quote text="It is always a pleasure to work with Probe team, they are always willing to help with additional data or provide information. I would recommend Probe to anyone."></Quote>
      <SliderDivider />
      <div className="pb-5"></div>
      <OtherCaseStudies names={['castle', 'edrone', 'devskiller']} />
    </CaseStudyLayout>
  )
}

export default CallpageCFO
